<template>
  <div class="fancy-text-block-nine mt-150">
    <div class="container">
      <div class="title-style-one text-center mb-35 md-mb-10">
        <div class="row">
          <div class="col m-auto">
            <div style="padding-bottom: 40px;">
              <img src="../../assets/Stowarzyszenie_Szansa_logo.png" alt="">
            </div>
            <h2 style="font-size: 40px;">POLITYKA OCHRONY DZIECI PRZED KRZYWDZENIEM W RAMACH STANDARDÓW OCHRONY MAŁOLETNICH OBOWIĄZUJĄCA W PLACÓWKACH STOWARZYSZENIA „SZANSA” W OŚWIĘCIMIU</h2>
          </div>
         <a style="padding: 20px;  font-weight: bold;">Pełna tresć dokumentu w formacie PDF - (wielkosć 346KB)</a>
          <download-pdf-button/>
          <div>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-sm-6 mx-auto">
          <div><img src="../../assets/images/shape/line-shape-5.svg"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-10 m-auto">
          <p
            class=""
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100">  
            PREAMBUŁA

Dla dobra dzieci, które potrzebują szczególnej ochrony i pomocy ze strony dorosły, środowiska
rodzinnego, atmosfery szczęścia, miłości i zrozumienia, w trosce o ich harmonijny rozwój i przyszłą samodzielność
życiową, dla zapewnienia ochrony przysługujących im praw i wolności, dla dobra rodziny, która jest podstawową
formą społeczeństwa oraz naturalnym środowiskiem rozwoju, i dobra wszystkich jej członków, a w szczególności
dzieci, w przekonaniu, że skuteczna pomoc dla rodzin przeżywających trudności w opiekowaniu się i wychowaniu
dzieci oraz skuteczna ochrona dzieci i pomoc dla nich może być osiągnięta przez współpracę wszystkich osób,
instytucji i organizacji pracujących z dziećmi i rodzicami.</p>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadPdfButton from '@/components/szansa/download-pdf-button.vue';

export default {
  name: "PolitykaOchorny",
  components: {
    DownloadPdfButton
  },
};
</script>
<style scoped>
p {
  text-align: justify;
  font-size: large;
  text-indent: 60px;
  line-height:40px;
}
</style>