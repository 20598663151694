<template>
  <div class="fancy-text-block-nine mt-150 md-mt-80">
    <div class="container">
      <div class="title-style-one text-center mb-35 md-mb-10">
        <div class="row">
          <div class="col m-auto">
            <h2>PRACE W TOKU...</h2>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-sm-4 mx-auto">
          <div><img src="../../assets/images/shape/line-shape-5.svg"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 m-auto">
          <img src="../../assets/work-in-progress.png" class="image">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "warsztatowe-centrum-kreatywnosci",
};
</script>
<style scoped>
p {
  text-align: justify;
  font-size: large;
  text-indent: 60px;
  line-height:40px;
}
li {
  line-height:40px;
  color: rgb(121, 120, 120);
  list-style-type: circle;
}
ul {
  list-style-type: circle;
}
h4 {
  color: #00a3e0;
}
.image {
  width: 80%; 
  float:none; 
  padding: 1%; 
  margin-top: 2%; 
  border-radius: 5%;
}
</style>