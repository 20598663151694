<template>
<div>
    <button class="button-container" @click="downloadPdf">Pobierz PDF</button>
</div>
</template>
  
<script>
import PdfFile from '@/assets/Polityka-Szansa.pdf';

  export default {
    methods: {
      downloadPdf() {
        const link = document.createElement('a');
        link.href = PdfFile; // Zmień na ścieżkę do twojego pliku PDF
        link.download = 'POLITYKA OCHRONY DZIECI PRZED KRZYWDZENIEM W RAMACH STANDARDÓW OCHRONY MAŁOLETNICH'; // Nazwa pliku, pod którą będzie pobierany
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        }
    }
    };
</script>
  
<style scoped>
.button-container {
    text-align: center;
    margin-top: 50px;

}

button {
  background-color: #d84315; /* Kolor pasujący do głównego stylu strony */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: 'Roboto', sans-serif; /* Dopasowanie do czcionki używanej na stronie */
  margin: 10px 0;
  cursor: pointer;
  border-radius: 4px; /* Delikatne zaokrąglenie rogów */
  transition: background-color 0.3s, transform 0.3s; /* Płynne przejścia */
  max-width: 200px; /* Maksymalna szerokość przycisku */
  width: 100%; /* Szerokość przycisku, która dostosowuje się do maksymalnej szerokości */
  box-sizing: border-box; /* Uwzględnia padding w szerokości przycisku */
}

button:hover {
  background-color: #bf360c; /* Ciemniejszy odcień pasujący do stylu strony przy najechaniu */
  transform: translateY(-2px); /* Subtelne podniesienie przycisku przy najechaniu */
}

button:active {
  background-color: #a52714; /* Jeszcze ciemniejszy odcień przy kliknięciu */
  transform: translateY(0); /* Powrót do normalnej pozycji przy kliknięciu */
}

button:focus {
  outline: none; /* Usunięcie domyślnej obwódki */
  box-shadow: 0 0 5px #d84315; /* Delikatna poświata pasująca do koloru przycisku przy fokusie */
}
</style>