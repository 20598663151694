<template>
  <div class="fancy-text-block-nine mt-150">
    <div class="container">
      <div class="title-style-one text-center mb-35 md-mb-10">
        <div class="row">
          <div class="col m-auto">
            <h2>Kreatywne Centrum Rozwoju</h2>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-sm-4 mx-auto">
          <div><img src="../../assets/images/shape/line-shape-5.svg"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-10 m-auto">
          <img src="../../assets/new_room.jpg" class="image">
          <p
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
            class="mt-20">  
            Placówka wsparcia dziennego prowadzona w formie opiekuńczej „Kreatywne Centrum Rozwoju” mieści się w Oświęcimiu, na osiedlu Stare Stawy przy ulicy Zagrodowej 1. Jest przeznaczona dla dzieci i młodzieży w wieku szkolnym od 7 lat do ukończenia 18 r.ż. (z możliwością przyjęcia dziecka w wieku 6 lat, od momentu rozpoczęcia przez niego nauki w szkole podstawowej). Świetlica czynna jest przez cały rok, w dniach od poniedziałku do piątku (z wyłączeniem dni wolnych od pracy i nauki).
          </p>
          <h4  data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
              class="mt-20">
              Głównym celem placówki jest:</h4>
          <ul data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
              class="list-group list-group-flush mt-20">
                <li>Objęcie dzieci i młodzieży w czasie wolnym od zajęć szkolnych opieką, zajęciami wychowawczymi oraz profilaktycznymi zapewniając dostępu do różnorodnych form spędzania czasu wolnego.</li>
                <li>Organizacja czasu wolnego, zabaw, zajęć sportowych oraz rozwoju zainteresowań podopiecznych.</li>
                <li>Propagowanie zdrowego stylu życia wśród dzieci.</li>
                <li>Pomoc w nauce wychowankom.</li>
                <li>Wspieranie rodziny w pełnieniu funkcji opiekuńczo – wychowawczej.</li>
                <li>Zapobieganie zachowaniom nieakceptowanym społecznie i szkodliwym dla zdrowia.</li>
                <li>Przeciwdziałanie marginalizacji i degradacji społecznej rodziny.</li>
                <li>Rozwijanie umiejętności opiekuńczo – wychowawczych rodziny. </li>
          </ul>
          <h4 data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
              class="mt-20"
              >Placówka ma za zadanie:</h4>
          <ul data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
              class="list-group list-group-flush mt-20">
            <li>Rozwijanie zainteresowań uzdolnień dzieci i młodzieży.</li>
            <li>Kształtowanie umiejętności konstruktywnego rozwiązywania problemów emocjonalnych, rodzinnych, rówieśniczych, szkolnych.</li>
            <li>Tworzenie klimatu sprzyjającego powstawaniu więzi wśród dzieci, uczenie zasad prawidłowej komunikacji interpersonalnej.</li>
            <li>Rozwijanie samodzielności, samorządności oraz aktywności społecznej.</li>
            <li>Kształtowanie prawidłowych postaw społecznych i kulturalnych.</li>
            <li>Wspieranie rozwój osobistego,  poczucia własnej wartości, motywacje do działania.</li>
            <li>Kształtowanie zachowań prozdrowotnych.</li>
            <li>Wyrównywanie szanse dostępu do dóbr i usług.</li>
            <li>Dożywianie.</li>
            <li>Tworzenie klimatu sprzyjającego powstawaniu więzi wśród dzieci, uczenie zasad prawidłowej komunikacji interpersonalnej.</li>
            <li>Kształtowanie zachowań prozdrowotnych i kompensowanie deficytów.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "warsztatowe-centrum-kreatywnosci",
};
</script>
<style scoped>
p {
  text-align: justify;
  font-size: large;
  text-indent: 60px;
  line-height:40px;
}
li {
  line-height:40px;
  color: rgb(121, 120, 120);
  list-style-type: circle;
}
ul {
  list-style-type: circle;
}
h4 {
  color: #00a3e0;
}
.image {
  width: 50%; 
  float: left; 
  padding: 1%; 
  margin-top: 2%; 
  border-radius: 5%;
}
</style>