<template>
  <div class="fancy-hero-four space-fix">
    <div class="bg-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-9 col-lg-11 col-md-10 m-auto">
            <h6></h6>
            <h2>Masz pytania ? Napisz do nas!</h2>
          </div>
        </div>
      </div>
    </div> <!-- /.bg-wrapper -->
  </div>
</template>

<script>
export default {
  name: 'FancyHero'
}
</script>