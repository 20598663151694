import {createRouter, createWebHistory} from 'vue-router'

import MainHomePage from '../components/szansa/main-home-page.vue';
import HistoriaSzansa from '../components/szansa/historia.vue';
import Cele from '../components/szansa/cele.vue';
import TeamSectionZarzad from '../components/szansa/team-section-zarzad.vue';
import SwietlicaWarsztat from '../components/szansa/swietlica-warsztat.vue';
import SwietlicaKreatywne from '../components/szansa/swietlica-kreatywne.vue';
import posredRodzPedagVue from '@/components/szansa/posred-rodz-pedag.vue';
import poradnictwoPsychologVue from '../components/szansa/porad-psychologiczne.vue';
import poradnictwoPrawneVue from '@/components/szansa/porad-prawne.vue';
import specjalistaUzaleznienVue from '@/components/szansa/specjalista-uzaleznien.vue';
import ContactStyleSzansa from '../components/szansa/contact-style.vue';
import PolitykaOchrony from '@/components/szansa/polityka-ochrony-dzieci.vue'
import WorkInProgress from '@/components/szansa/work-in-progress.vue';
import NotFound from '../pages/404';


const routes = [
    {
        path: '/',
        name: 'portfolio-v3',
        component: MainHomePage,
        meta: {
            title: 'Stowarzyszenie Szansa',
        },
    },
    {
        path: '/history',
        name: 'History',
        component: HistoriaSzansa,
        meta: {
            title: 'SZANSA - Historia',
        },
    },
    {
        path: '/nasze-cele',
        name: 'Nasze-Cele',
        component: Cele,
        meta: {
            title: 'SZANSA - Nasze cele',
        },
    },
    {
        path: '/szansa-zarzad',
        name: 'Zarząd',
        component: TeamSectionZarzad,
        meta: {
            title: 'SZANSA - Zarzad',
        },
    },
    {
        path: '/contact-style',
        name: 'kontakt',
        component: ContactStyleSzansa,
        meta: {
            title: 'SZANSA - Kontakt',
        },
    },
    {
        path: '/polityka-ochrony-dzieci',
        name: 'PolitykaOchrony',
        component: PolitykaOchrony,
        meta: {
            title: 'SZANSA - Polityka Ochrony Dzieci',
        },
    },
    {
        path: '/warsztatowe-centrum-kreatywnosci',
        name: 'warsztatowe-centrum-kreatywnosci',
        component: SwietlicaWarsztat,
        meta: {
            title: 'SZANSA - Warsztatowe Centrum Kreatywnosci',
        },
    },
    {
        path: '/kreatywne-centrum-rozwoju',
        name: 'kreatywne-centrum-rozwoju',
        component: SwietlicaKreatywne,
        meta: {
            title: 'SZANSA - Kreatywne Centrum Rozwoju',
        },
    },
    {
        path: '/posrednictwo-rodzinno-pedagogiczne',
        name: 'posrednictwo-rodzinno-pedagogiczne',
        component: posredRodzPedagVue,
        meta: {
            title: 'SZANSA - Pośrednictwo Rodzinno-Pedagogiczne',
        },
    },
    {
        path: '/poradnictwo-psychologiczne',
        name: 'poradnictwo-psychologiczne',
        component: poradnictwoPsychologVue,
        meta: {
            title: 'SZANSA - Poradnictwo Psychologiczne',
        },
    },
    {
        path: '/poradnictwo-prawne',
        name: 'poradnictwo-prawne',
        component: poradnictwoPrawneVue,
        meta: {
            title: 'SZANSA - Poradnictwo Prawne',
        },
    },
    {
        path: '/specjalista-ds-uzaleznien',
        name: 'specjalista-ds-uzaleznien',
        component: specjalistaUzaleznienVue,
        meta: {
            title: 'SZANSA - Specjalista ds. uzależnień',
        },
    },
    {
        path: '/work-in-progress',
        name: 'work-in-progress',
        component: WorkInProgress,
        meta: {
            title: 'SZANSA - Kreatywne Centrum Rozwoju',
        },
    },
    {
        path: '/not-found',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'NotFound || Deski-Saas & Software Vue Template',
        },
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'NotFound',
        component: NotFound,
        meta: {
            title: 'NotFound || Deski-Saas & Software Vue Template',
        },
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    document.title = to.meta.title;
    next();
    window.scrollTo(0, 0)
});

export default router
