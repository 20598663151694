<template>
  <div class="fancy-text-block-nine mt-150">
    <div class="container">
      <div class="title-style-one text-center mb-35 md-mb-10">
        <div class="row">
          <div class="col m-auto">
            <h2>Warsztatowe Centrum Kreatywności</h2>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-sm-4 mx-auto">
          <div><img src="../../assets/images/shape/line-shape-5.svg"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-10 m-auto">
          <img src="../../assets/new_room.jpg" class="image">
          <p
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
            class="mt-20">  
            Placówka wsparcia dziennego prowadzona w formie specjalistycznej „Warsztatowe Centrum Kreatywności” mieści się w Oświęcimiu na osiedlu Zasole przy ulicy Więźniów Oświęcimia 10. Jest przeznaczona dla dzieci i młodzieży w wieku szkolnym od 7 lat do ukończenia 18 r.ż. (z możliwością przyjęcia dziecka w wieku 6 lat, od momentu rozpoczęcia przez niego nauki w szkole podstawowej). Świetlica czynna jest przez cały rok, w dniach od poniedziałku do piątku (z wyłączeniem dni wolnych od pracy i nauki).
          </p>
          <h4  data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
              class="mt-20">
              Głównym celem placówki jest:</h4>
          <ul data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
              class="list-group list-group-flush mt-20">
                <li>Objęcie dzieci i młodzieży w czasie wolnym od zajęć szkolnych opieką, zajęciami wychowawczymi oraz profilaktycznymi zapewniając dostępu do różnorodnych form spędzania czasu wolnego.</li>
                <li>Prowadzenie zajęć specjalistycznych.</li>
                <li>Opieka  i pomoc specjalistów.</li>
                <li>Organizacja czasu wolnego, zabaw, zajęć sportowych oraz rozwoju zainteresowań podopiecznych.</li>
                <li>Propagowanie zdrowego stylu życia wśród dzieci.</li>
                <li>Pomoc w nauce wychowankom z trudnościami uczenia się i przyswajania wiedzy oraz niwelowanie braków szkolnych. </li>
                <li>Wspieranie rodziny w pełnieniu funkcji opiekuńczo-wychowawczej.</li>
                <li>Zapobieganie zachowaniom nieakceptowanym społecznie i szkodliwym dla zdrowia.</li>
                <li>Przeciwdziałanie marginalizacji i degradacji społecznej rodziny.</li>
                <li>Rozwijanie umiejętności opiekuńczo – wychowawczych rodziny. </li>
          </ul>
          <h4 data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
              class="mt-20">Placówka ma za zadanie:</h4>
          <ul data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="100"
              class="list-group list-group-flush mt-20">
            <li>Rozwijanie zainteresowań uzdolnień dzieci i młodzieży.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "warsztatowe-centrum-kreatywnosci",
};
</script>
<style scoped>
p {
  text-align: justify;
  font-size: large;
  text-indent: 60px;
  line-height:40px;
}
li {
  line-height:40px;
  color: rgb(121, 120, 120);
  list-style-type: circle;
}
ul {
  list-style-type: circle;
}
h4 {
  color: #00a3e0;
}
.image {
  width: 50%; 
  float: left; 
  padding: 1%; 
  margin-top: 2%; 
  border-radius: 5%;
}
</style>