<template>
  <div class="fancy-text-block-nine mt-150">
    <div class="container">
      <div class="title-style-one text-center mb-35 md-mb-10">
        <div class="row">
          <div class="col m-auto">
            <h2>Pośrednictwo Rodzinno-Pedagogiczne</h2>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
        <div class="col-sm-4 mx-auto">
          <div><img src="../../assets/images/shape/line-shape-5.svg"></div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-10 m-auto">
          <img src="../../assets/pexels-alex-green-5699418.jpg" class="image">
          <p
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay="100"
            class="mt-20">  
            Poradnictwo rodzinne obejmujące szeroko rozumiane problemy funkcjonowania rodziny.
Pomoc w radzeniu sobie z problemami wychowawczymi, prowadzenie mediacji rodzinnych.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "posrednictwo-rodzinno-pedagogiczne",
};
</script>
<style scoped>
p {
  text-align: justify;
  font-size: large;
  text-indent: 60px;
  line-height:40px;
}
li {
  line-height:40px;
  color: rgb(121, 120, 120);
  list-style-type: circle;
}
ul {
  list-style-type: circle;
}
h4 {
  color: #00a3e0;
}
.image {
  width: 50%; 
  float: left; 
  padding: 1%; 
  margin-top: 2%; 
  border-radius: 5%;
}
</style>