<template>
  <div class="fancy-hero-six mt-140">
    <div class="container">
      <div class="m-5" >
        <div class="row">
          <div class="col-sm-6" style="height: 200px;">
            <div class="logoImage">
              <img src="../../assets/Stowarzyszenie_Szansa_logo.png" alt="">
            </div>
            <div v-if="isImage" class="logoVideo">
              <img src="../../assets/Stowarzyszenie_Szansa_logo.png" alt="">
            </div>
            <div v-else class="logoVideo">
              <video loop muted playsinline autoplay class="clip-polygon">
                <source src="../../assets/podrzucaniePilki2.webm" type="video/webm">
                Twoja przeglądarka nie obsługuje odtwarzania tego video.
              </video>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="wrapperGallery">
            <img class="ImgGallery" src="../../assets/images/gallery_main_page/1.jpg" alt="">
            <img class="ImgGallery" src="../../assets/images/gallery_main_page/2.jpg" alt="">
            <img class="ImgGallery" src="../../assets/images/gallery_main_page/3.jpg" alt="">
            <img class="ImgGallery" src="../../assets/images/gallery_main_page/4.jpg" alt="">
          </div>
          </div>
        </div>

        <div class="row" style="float: none; text-align: center;">
          <div class="col-sm-12">
            <p class="sub-heading">Bogatym nie jest ten, kto posiada, lecz ten, kto daje</p>
          <p>- Jan Paweł II</p>
          </div>
          
        </div>
        <div class="row" style="padding-top: 5%;">
          <div class="col-sm-12" >
              <video width="640" height="360" controls loop style="border: 2px solid white;
                                                              border-radius:15px;">
                <source src="../../assets/SZANSA_VIDEO_MP4.mp4#t=1.1" type="video/mp4">
                  Your browser does not support the video tag.
              </video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FancyHero',
  data () {
    return {
      isImage: true, // Początkowo wyświetlamy obrazek
      intervalId: null, // Identyfikator interwału, potrzebny do późniejszego czyszczenia
    };
  },
  mounted() {
    // Ustawienie interwału, który co 5 sekund wywoła funkcję changeClass
    this.intervalId = setInterval(this.toggleMedia, 8080);
  },
  beforeUnmount() {
    // Przed odmontowaniem komponentu, wyczyść interwał, aby uniknąć wycieków pamięci
    clearInterval(this.intervalId);
  },
  methods: {
    toggleMedia() {// Funkcja zmieniająca klasę na przeciwną
      this.isImage = !this.isImage;
    },
  },
};
</script>
<style scoped>
.clip-polygon {
  /* mask-image: url(../../assets/Stowarzyszenie_Szansa_logo.png); */
  /* mask-repeat: no-repeat; */
  width: 100%;
  height: 169px;
  object-fit: cover;
  /* z-index: -100; */
  margin-top: 0px;
}
.wrapperGallery {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 4vmin;
  filter: drop-shadow(-1px 6px 3px rgba(50, 50, 0, 0.5));
}
.ImgGallery {
  grid-column: span 2;
  margin-bottom: -52%;
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  transition: all .25s;
  animation: pulse-animation 2s;
  &:nth-child(7n + 1) {
    grid-column: 2 / span 2; 
  }
  &:hover {
    z-index: 2;
    transform: scale(1.4);
  }
}
@keyframes pulse-animation {
  0% {
    transform: scale(0.1);
  }
  1% {
    transform: scale(0.2);
  }
  2% {
    transform: scale(0.3);
  }
  5% {
    transform: scale(0.2);
  }
}
.body{
  background: #ffffff url('../../assets/tumblr_static_bg3.png') repeat 0 0;
  -webkit-animation: 10s linear 0s normal none infinite animate;
  -moz-animation: 10s linear 0s normal none infinite animate;
  -ms-animation: 10s linear 0s normal none infinite animate;
  -o-animation: 10s linear 0s normal none infinite animate;
  animation: 10s linear 0s normal none infinite animate;
}
@-webkit-keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}
@-moz-keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}
@-ms-keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}
@-o-keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}
@keyframes animate {
  from {background-position:0 0;}
  to {background-position: 500px 0;}
}

@media (min-width: 1200px) {
.logoImage {
  display: none;
}
}

@media (max-width: 1199px) {
.logoVideo {
  display: none;
}
}

@media (max-width: 992px) {
.logoVideo {
  display: none;
}
}

@media (min-width: 768px) {

}

@media (min-width: 576px) {

}
@media (max-width: 576px) {
  .wrapperGallery {
  display: none;
}
.logoVideo {
  display: none;
}
.clip-polygon {
  /* mask-image: url(../../assets/Stowarzyszenie_Szansa_logo.png); */
  /* mask-repeat: no-repeat; */
  margin-top: 20px;
}

 }
</style>